import "./App.css";

function App() {
  return (
    <div
      className="App"
      style={{
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
      }}
    >
      <div className="d-block my-4">
        <h1 className="display-4 font-weight-bold mb-3"> Appents </h1>
        <p>Coming soon</p>
        <p>
          contact us - <a href="mailto:info@appents.com">info@appents.com</a>
        </p>
      </div>

      <div style={{ marginTop: 200 }}>
        <h1>We're hiring</h1>
        1.{" "}
        <a href="https://docs.google.com/document/d/1BPp7kuXdlVWm6YQ2PdRemTUws3ky0sak-Py1MXxk_Yk/">
          Indexing Lead Engineer
        </a>
        <br />
        <p>
          send your resume to -{" "}
          <a href="mailto:jobs@appents.com">jobs@appents.com</a>
        </p>
      </div>
    </div>
  );
}

export default App;
